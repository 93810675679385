import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

const PageBreadcrumbs = props => {
    return (
        <Breadcrumb style={{ marginTop: 10 }}>
            {props.routes.map(route => {
                return <Breadcrumb.Item key={route.label}>{route.path ? <Link to={route.path}>{route.label}</Link> : route.label}</Breadcrumb.Item>;
            })}
        </Breadcrumb>
    );
};

export default PageBreadcrumbs;
