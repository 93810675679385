import { SET_USER } from '../actionTypes'

const initialState = {
    login: undefined,
}

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_USER: {
            const { login } = action.payload
            return {
                ...state,
                login: login,
            }
        }
        default:
            return state
    }
}
