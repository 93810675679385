import axios from 'axios';
import {API_url} from './../api/const';

const instance = axios.create({
    baseURL: API_url+'/api/v1/',
    headers: {
        Authorization: {
            toString() {
                return `Bearer ${localStorage.getItem('accessToken')}`;
            },
        },
    },
});

export default instance;
