import axios from 'axios'
import {API_url} from './../api/const';

export const API_login = (login, password) => {
    return axios
        .post(API_url+'/api/v1/login', {
            login: login,
            pwd: password,
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
